
<template>
  <div
    :class="[getBrowserVersion(), locationClass]"
  >
    <router-view />
  </div>
</template>

<script>

if (process.env.VUE_APP_FEEDBACK == 1) {
    window.onUsersnapCXLoad = function(api) {
        api.init();
    }
    var script = document.createElement('script');
    script.defer = 1;
    script.src = 'https://widget.usersnap.com/global/load/94028a49-69a1-47f0-bdfd-411daaf02510?onload=onUsersnapCXLoad';
    document.getElementsByTagName('head')[0].appendChild(script);
}

import * as _location from './lib/location'

export default {
    name: 'App',
    data() {
        return {
            show: true,
            locationClass: ''
        }
    },

    mounted() {
        const location = _location.get();
        this.locationClass = 'location-' + location.slug; // Set the location class dynamically
    },

    methods: {
        getBrowserVersion () {
            let userAgent = navigator.userAgent;
            if (userAgent.match(/chrome|chromium|crios/i)) {
                return 'chrome';
            } else if (userAgent.match(/firefox|fxios/i)) {
                return 'firefox';
            } else if (userAgent.match(/safari/i)) {
                return 'safari';
            } else if (userAgent.match(/opr\//i)) {
                return 'opera';
            } else if (userAgent.match(/edg/i)) {
                return 'edge';
            }
            return false;
        },
    }
}

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

window.addEventListener( "pageshow", function ( event ) {
    const historyTraversal = event.persisted || (typeof window.performance != "undefined" && window.performance.navigation.type === 2);
    if(historyTraversal) {
        window.location.reload();
    }
});

</script>

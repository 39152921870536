<template>
  <status-booting v-if="booted === false && cssLoaded === false" />
  <div v-else>
    <div
      :class="
        transaction.method +
          ' ' +
          config.theme.cart_style +
          ' ' +
          config.theme.tile_radius +
          ' ' +
          config.theme.tile_shadow +
          ' ' +
          config.theme.button_radius +
          ' ' +
          config.theme.button_text_style +
          ' ' +
          config.theme.button_border_width +
          ' ' +
          config.theme.button_font_weight +
          ' ' +
          config.theme.button_font +
          ' ' +
          config.theme.cart_position
      "
    >
      <layout-navigation />

      <div
        v-if="config.settings.background_image"
        class="main-background-image"
        :style="[
          { backgroundImage: 'url(' + config.settings.background_image + ')' },
          { backgroundPosition: config.theme.background_position },
          { backgroundSize: config.theme.background_size },
          { backgroundRepeat: config.theme.background_repeat },
          { backgroundAttachment: config.theme.background_size },
        ]"
      >
        <div class="main-background-gradient" />
      </div>

      <div class="relative min-h-screen flex flex-col sm:flex main-wrapper">
        <div class="flex-grow">
          <main
            class="padding-divider"
            :class="[
              { 'opening-hours-padding': config.theme.show_opening_hours == true && location && location.slug },
              { 'extra-padding-products': padding_fixer_active === true },
            ]"
          >
            <div class="max-w-7xl mx-auto px-4 content-padding">
              <div class="wrapper">
                <div class="container-fluid">
                  <modal-user-notifications />

                  <!-- <router-view :tableopen="tableOpenWatcher()" /> -->

                  <!--                                    <transition name="slide-page-left">-->
                  <router-view v-slot="{ Component }">
                    <transition name="slide-page-left">
                      <component :is="Component" />
                    </transition>
                  </router-view>
                  <!--                                    </transition>-->
                </div>
              </div>
              <!-- <router-view :tableopen="tableOpenWatcher()" /> -->
              <!-- <router-view /> -->
            </div>
          </main>
        </div>
        <modal-auto-close-table />
        <layout-footer />
      </div>
    </div>
  </div>
  <div v-if="booted == false || cssLoaded === false">
    <!-- <button @click="$refs.login.open()">log in</button> -->
    <modal-login
      ref="login"
      @open-accounts="$refs.accounts.open()"
      @open-register="$refs.register.open()"
      @open-passwordforget="$refs.passwordforget.open()"
    />
    <modal-register
      ref="register"
      @open-login="$refs.login.open()"
    />
    <modal-password-forget
      ref="passwordforget"
      @open-login="$refs.login.open()"
    />
  </div>
</template>

<script>
import axios from 'axios';
// import router from '../router';

// libs
import * as _api from '../lib/api';
import * as _state from '../lib/state';
import * as _location from '../lib/location';
import * as _log from '../lib/log';
import * as _applicationState from '../lib/application-state';
import * as _transaction from '../lib/transaction';
import * as _notification from '../lib/notification';

import LayoutFooter from '../components/Footer';
import LayoutNavigation from '../components/Navigation';
import ModalUserNotifications from '../components/ModalUserNotifications';
import StatusBooting from '../components/StatusBooting';

import ModalLogin from '../components/ModalLogin';
import ModalRegister from '../components/ModalRegister';
import ModalPasswordForget from '../components/ModalPasswordForget';
import ModalAutoCloseTable from '../components/ModalAutoCloseTable';
import applicationState from '../states/application-state';

// set the logger
const logger = _log.get('BOOT (app)');

export default {
    name: 'Boot',
    components: {
        LayoutFooter,
        LayoutNavigation,
        ModalUserNotifications,
        ModalLogin,
        ModalRegister,
        ModalPasswordForget,
        StatusBooting,
        ModalAutoCloseTable,
    },
    props: ['location_slug'],

    data() {
        return {
            booted: false,
            cssLoaded: false,

            restricedRoutesAccess: {
                // 'deliverymethod': 'delivery,takeaway',
                'checkout.table': 'tables',
                areaselection: 'tables',
                isHooli: false,
                areatableselection: 'tables',
                tableservice: 'tables',
                'table.open': 'tables',
                delivery: 'delivery',
                takeaway: 'takeaway',
                menu: 'menu',
                lockers: 'elockers',
                reservation: 'reservation',
                quickorder: 'quickorder',
                visitors: 'visitors',
                'checkout.wallet': 'wallet',
            },
        };
    },
    computed: {
        current_route() {
            return this.$route.name;
        },

        padding_fixer_active() {
            let routes = ['menu', 'delivery', 'takeaway', 'quickorder', 'table', 'tableservice'];

            return routes.includes(this.current_route);
        },

        config() {
            return _state.get('config/getConfig');
        },

        transaction() {
            return _state.get('transaction/getTransaction');
        },

        location() {
            return _state.get('location/getLocation');
        },
    },
    watch: {
        $route() {
            _transaction.setExpireLockerTimer();
        },
    },
    async mounted() {
        // boot the app (get the config and settings)
        await this.boot();

        if (this.$route.name === 'boot') {
            this.$router.replace({ name: _applicationState.getStartPage() });
        }

        // if (this.booted === false) {
        //     this.$refs.login.open();
        // }
        _transaction.setExpireLockerTimer();

        

    },

    methods: {
        async boot() {
            logger.log('booting..')();

            // todo: to boot
            // await db.initialize();
            // await db.sync();

            // let paymenttypes = await db.get('paymenttypes');
            // let categories = await db.get('categories');
            // let terminal = await db.get('terminal');
            if ((await _applicationState.isWebshopActive()) === false) {
                this.$router.replace({ name: 'status.inactive' });
                return;
            }

          

            // initilize application state
            _applicationState.init();

            // location
            await _location.init(this.location_slug);

            if (this.location_slug) {
                axios.defaults.headers.common['Location'] = this.location_slug;
            }

            // has access
            if (this.location_slug && this.canAccess() === false) {
                // return this.$router.push({ name: '403'});
                location.href = '/403';
                return;
            }

              if (this.$route.query.hooliMemberEmail || this.$route.query.hooliMemberId || this.$route.query.hooliEventId) {
                this.isHooli = true;
                _applicationState.setIsHooli(true);
              }

            // is the location open
            //Remove modal-open class for removing scroll disable
            // document.body.classList.remove('modal-open');
            const restricedRoutesOpenLocation = [
                'checkout.table',
                'areaselection',
                'areatableselection',
                'tableservice',
                'table.open',
                // 'takeaway',
                'quickorder',
            ];

            if ((await _location.isOpen()) === false && restricedRoutesOpenLocation.includes(this.$route.name)) {
                location.href = '/closed';
                return;
            }

            // initilize config
            const config = await this.loadConfig();

            // set the config in the state
            const current_config = _state.get('config/getConfig');
            
            // localization
            const language = current_config.settings.language ? current_config.localization : config.settings.language;

            this.$i18n.locale = language;
            axios.defaults.headers.common['Localization'] = language;

            if (!config.theme && !this.isHooli) {
                _notification.set('notification.webshop.theme.not-selected', 'error');
                return;
            }

            _state.set('config/setConfig', {
                theme: config.theme,
                settings: config.settings,
                legals: config.legals ?? false,
                localization: language,
            });

            logger.log('config initilized..')();

            const themeUrl = this.isHooli
              ? '/css/hooli.css'
              : config.settings.theme; 
            

            this.setCssTheme(themeUrl);

            // set authentication required
            _applicationState.setAuthenticationRequired(config.settings.authentication_required);

            if (_applicationState.isAuthenticationRequired() && _applicationState.userLoggedIn() === false) {
                this.$refs.login.open();
                return false;
            }

            // init transaction
            this.initTransaction();

            // set the startpage
            if (config.settings.location_selection == true) {
                _applicationState.setStartPage('locations');
            }

            logger.log('booting finished..')();

            this.booted = true;
        },

        canAccess() {
            if (!this.restricedRoutesAccess[this.$route.name]) {
                return true;
            }

            // let access = this.restricedRoutesAccess[this.$route.name].split(',');
            if (_applicationState.canAccessFunction(this.restricedRoutesAccess[this.$route.name]) === true) {
                return true;
            }

            return false;
        },

        initTransaction() {
            if (this.transaction) {
                return;
            }
            _transaction.init();
        },

        loadTheme() {
            return {
                categories_size: 'categories-normal',
                tile_radius: 'tiles-radius-small',
                tile_shadow: 'tiles-shadow',
                button_radius: 'button-radius-small',
                button_border_width: 2,
                button_font: 'button-font-text',
                button_text_style: 'button-text-normal',
                button_font_weight: 'button-font-normal',
                cart_position: 'cart-right',
                show_opening_hours: false,
                background_position: 'center center',
                background_size: 'cover',
                background_repeat: 'no-repeat',
                product_view_mode: 'start-with-tiles',
                categories_position: 'categories-default',
                category_background_style: 'no-background',
                cart_style: 'cart-fixed',
                category_image_option: 'product-image',
                product_style_list_view: 'list-hide-image',
                product_full_background_text_position: 'text-bottom',
                list_image_style: 'cover',
                tiles_text_style: 'divided',
                tiles_image_style: 'cover',
                tiles_image_size: 'tiles-image-medium',
                tiles_image_added_padding: 'tiles-added-image-padding padding-small',
                tiles_ordering: 'tiles-stack',
                overflow_tiles_size: 'overflow-tiles-normal',
                category_image_style: 'categories-image-fill',
                mobile_category_style: 'normal-spacing',
                mobile_category_font_style: 'bold',
                mobile_columns: 'mobile-columns-2',
                desktop_columns: 'desktop-columns-4',
                custom_gradient_product_background: 'custom-gradient',
                list_add_to_cart_arrangement: 'plus-icon',
            };
        },

        async loadConfig() {
            // load  initial settings
            const response = await _api.post('config');

            return {
                settings: response.data.settings.attributes,
                legals: response.data.legals,
                theme: response.data.theme,
            };
        },

        setCssTheme(theme) {
            if (theme) {
                let style = document.createElement('link');
                style.type = 'text/css';
                style.rel = 'stylesheet';
                style.href = theme;
                document.head.appendChild(style);

                const cssnum = document.styleSheets.length;

                var styleTimeput = setInterval(function () {
                    if (document.styleSheets.length > cssnum) {
                        self.cssLoaded = true;
                        clearInterval(styleTimeput);
                    }
                }, 10);
            }
        },

        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        },
    },
};
</script>
